import React from 'react'
import 'react-quill/dist/quill.snow.css'
import { Button, InputLabel, TextField } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { navigate } from 'gatsby'
import { useStyles } from './style'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { useTranslation } from 'react-i18next'
import { StrategyAction } from '../../../Strategy/state'
import { CONTENT_ADMIN_ROUTES, ROUTES, STRATEGY_TYPE_ENUM } from '../../../../constant'

export default function CreateStrategyEditor() {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const [name, setTitleEn] = React.useState('')
  const [titleVi, setTitleVi] = React.useState('')
  const [description, setDescriptionEn] = React.useState('')
  const [descriptionVi, setDescriptionVi] = React.useState('')
  const [strategyType, setStrategyType] = React.useState(STRATEGY_TYPE_ENUM[0])
  const [expectedProfit, setExpectedProfit] = React.useState(0)
  const [expectedMaxDrawdown, setExpectedMaxDrawdown] = React.useState(0)

  const handleClickPublish = () => {
    StrategyAction.create(name, titleVi, description, descriptionVi, strategyType, expectedProfit, expectedMaxDrawdown)()
    navigate(`${ROUTES.CONTENT_ADMIN}${CONTENT_ADMIN_ROUTES.STRATEGY}`)
  }

  return (
    <Grid className={classes.div} container spacing={3}>
      <Grid item xs={12} md={6}>
        <TextField
          className={classes.root}
          fullWidth
          label={'Title English'}
          value={name}
          onChange={(e) => setTitleEn(e.target.value)}
          variant="outlined"
        />
        <TextField
          className={classes.root}
          fullWidth
          multiline
          label={'Description English'}
          value={description}
          onChange={(e) => setDescriptionEn(e.target.value)}
          variant="outlined"
          rows={4}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          className={classes.root}
          fullWidth
          label={'Title Vietnamese'}
          value={titleVi}
          onChange={(e) => setTitleVi(e.target.value)}
          variant="outlined"
        />

        <TextField
          className={classes.root}
          fullWidth
          multiline
          label={'Description Vietnamese'}
          value={descriptionVi}
          onChange={(e) => setDescriptionVi(e.target.value)}
          variant="outlined"
          rows={4}
        />
      </Grid>
      <Grid container item xs={12} md={12} justify={'space-around'}>
        <FormControl variant="outlined">
          <InputLabel>Type</InputLabel>
          <Select
            className={classes.category}
            value={strategyType}
            onChange={(e) => setStrategyType(e.target.value as string)}
            label="Type"
          >
            {STRATEGY_TYPE_ENUM.map((record) => (
              <MenuItem value={record}>{t(record)}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          className={classes.profit}
          type="number"
          label={'Expected Profit'}
          value={expectedProfit}
          onChange={(e) => setExpectedProfit(+e.target.value)}
          variant="outlined"
        />

        <TextField
          className={classes.drawdown}
          type="number"
          label={`Expected Max Drawdown (without '-')`}
          value={expectedMaxDrawdown}
          onChange={(e) => setExpectedMaxDrawdown(+e.target.value)}
          variant="outlined"
        />

        <Button className={classes.publishButton} variant="contained" color="primary" onClick={handleClickPublish}>
          Đăng bài
        </Button>
      </Grid>
    </Grid>
  )
}
